const AppColors = {};

AppColors.darkTheme = {
    homeBackground: '#303030',
    homeBackgroundGradient: '#303030',
    homeAppBarColor: '#303030',
    homePrimary: '#BEBEBE',
    homeSecondary: '#3deb69b7',
    homeMenuBtn: '#3deb69b7',
    resumeBackground: '#303030',
    resumePrimary: '#3deb69b7',
    resumeSecondary: '#BEBEBE',
    resumeAppBarColor: '#303030',
    portfolioBackground: '#252627',
    portfolioBackground2: '#252627',
    portfolioPrimary: '#3deb69b7',
    portfolioSecondary: '#BEBEBE',
    grey: '#BEBEBE',
    contactPrimary: '#BEBEBE',
    contactSecondary: '#3deb69b7',
    contactTirtiary: '#0D1117',
};

AppColors.lightTheme = {
  homeBackground: '#DCDCDC',
  homeBackgroundGradient: 'white',
  homeAppBarColor: '#3f51b5',
  homePrimary: '#252627',
  homeSecondary: '#3f51b5',
  homeMenuBtn: '#0D1117',
  resumeBackground: 'white',
  resumePrimary: '#3f51b5',
  resumeSecondary: '#252627',
  resumeAppBarColor: '#3f51b5',
  portfolioBackground: '#DCDCDC',
  portfolioBackground2: 'white',
  portfolioPrimary: '#3f51b5',
  portfolioSecondary: '#252627',
  grey: '#BEBEBE',
  contactPrimary: '#252627',
  contactSecondary: '#3f51b5',
  contactTirtiary: '#eeeeee',
};

export default AppColors;